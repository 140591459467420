import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import './CreateOffer.css';
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete, AiOutlineClose } from 'react-icons/ai';
import Loader from '../components/Loader';
import { useNavigate, Navigate } from 'react-router-dom';
import { AuthContext } from '../components/auth'; 

const CreateOffer = () => {
  const [offers, setOffers] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState(null);
  const [showDeleteCouponPopup, setShowDeleteCouponPopup] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
const [showProductDetailsPopup, setShowProductDetailsPopup] = useState(false);
const [loading, setLoading] = useState(false); // New state for loading
const { authState, logout } =useContext(AuthContext);

const fetchProductDetails = async (productId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    console.log(response);
    setProductDetails(response.data);
    setShowProductDetailsPopup(true);
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/offer/`);
        setOffers(response.data);
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };

    const fetchCoupons = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/coupon/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCoupons(response.data);
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    };
  

    fetchOffers();
    fetchCoupons();
  }, []);

  const handleCreateOffer = async (e) => {
    e.preventDefault();
       setLoading(true); 

    const offerData = {
      name: e.target.name.value,
      discountPercentage: e.target.discountPercentage.value,
      productId: e.target.productId.value,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/offer/offers`, offerData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (e.target.productImage.files[0]) {
        const formData = new FormData();
        formData.append('image', e.target.productImage.files[0]);

        await axios.put(`${process.env.REACT_APP_API_URL}/api/offer/${response.data._id}/image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
      }

      setOffers([...offers, response.data]);
      setShowPopup(false);
    } catch (error) {
      console.error('Error creating offer:', error);
    }
     finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleDeleteOffer = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/offer/offers/${currentOffer._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOffers(offers.filter((offer) => offer._id !== currentOffer._id));
      setShowDeletePopup(false);
    } catch (error) {
      console.error('Error deleting offer:', error);
    }
  };

  const handleEditOffer = async (e) => {
    e.preventDefault();
    const offerData = {
      name: e.target.name.value,
      discountPercentage: e.target.discountPercentage.value,
      productId: e.target.productId.value,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/offer/${currentOffer._id}`, offerData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (e.target.productImage.files[0]) {
        const formData = new FormData();
        formData.append('image', e.target.productImage.files[0]);

        await axios.put(`${process.env.REACT_APP_API_URL}/api/offer/${currentOffer._id}/image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
      }

      const updatedOffers = offers.map((offer) =>
        offer._id === currentOffer._id ? response.data : offer
      );
      setOffers(updatedOffers);
      setShowPopup(false);
    } catch (error) {
      console.error('Error editing offer:', error);
    }
  };

  const handleCreateCoupon = async (e) => {
    e.preventDefault();
    const newCoupon = {
      name: e.target.couponCode.value,
      discount: e.target.discountPercentage.value,
      expiry: e.target.expiryDate.value,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/coupon/`, newCoupon, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCoupons([...coupons, response.data]);
      setShowCouponPopup(false);
    } catch (error) {
      console.error('Error creating coupon:', error);
    }
  };

  const handleDeleteCoupon = async (couponId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/coupon/${couponId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCoupons(coupons.filter((coupon) => coupon._id !== couponId));
      setShowDeleteCouponPopup(false);
    } catch (error) {
      console.error('Error deleting coupon:', error);
    }
  };

   if (authState.authLoading) {
    return <Loader />; // or any other loading indicator
  }
  

  return  authState.isAuthenticated ?  (
    <div>
      <Sidebar />
      <div className="create-offer-page">
        <div className="main-content">
          <div className="button-container">
            <button className="create-offer-btn" onClick={() => setShowPopup(true)}>
              <AiOutlinePlus /> Create New Offer
            </button>
            <button className="create-coupon-btn" onClick={() => setShowCouponPopup(true)}>
              <AiOutlinePlus /> Create Coupon
            </button>
          </div>
          <h1>Offers</h1>
          <div className="offers-list">
            {offers.map((offer) => (
              <div key={offer._id} className="offer-card">
                <img src={offer.image} alt="Product"  />
                <div className="offer-details">
                  <p>{offer.discountPercentage}% off</p>
                  <p>Product Title: {offer.productId?.title || 'No title'}</p>
                  <p>Product Category: {offer.productId?.category || 'No category'}</p>
                  <p>Product Price: {offer.productId?.price || 'No price'}</p>
                  
                  <button onClick={() => { setCurrentOffer(offer); setShowDeletePopup(true); }}>
                    <AiOutlineDelete /> Delete
                  </button>
                  <button onClick={() => { fetchProductDetails(offer.productId._id); }}>
                  View Details
                </button>
                </div>
              </div>
            ))}
          </div>

          <h1>Coupons</h1>
          <div className="coupons-list">
            {coupons.map((coupon) => (
              <div key={coupon._id} className="coupon-card">
                <p>Coupon code: <strong>{coupon.name}</strong> <br />
                  Discount: <strong>{coupon.discount}% off</strong><br />
                  Expires on: <strong>{coupon.expiry}</strong>
                </p>
                <button onClick={() => { setCurrentCoupon(coupon); setShowDeleteCouponPopup(true); }} className='delte'>
                  <AiOutlineDelete /> Delete
                </button>
              </div>
            ))}
          </div>
        </div>

        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-box">
              <button className="close-icon" onClick={() => setShowPopup(false)}>
                <AiOutlineClose />
              </button>
              <form onSubmit={currentOffer ? handleEditOffer : handleCreateOffer}>
                <h2>{currentOffer ? 'Edit Offer' : 'Create New Offer'}</h2>
                <input type="text" name="name" defaultValue={currentOffer ? currentOffer.name : ''} placeholder="Offer Name" required />
                <input type="number" name="discountPercentage" defaultValue={currentOffer ? currentOffer.discountPercentage : ''} placeholder="Discount Percentage" required />
                <input type="text" name="productId" defaultValue={currentOffer ? currentOffer.productId : ''} placeholder="Product ID" required />
                <input type="file" name="productImage" />
                <button type="submit">{currentOffer ? 'Update Offer' : 'Create Offer'}</button>
              </form>
            </div>
          </div>
        )}

        {showDeletePopup && (
          <div className="popup-overlay">
            <div className="popup-box">
              <p>Are you sure you want to delete this offer?</p>
              <button onClick={handleDeleteOffer}>Yes, Delete</button>
              <button onClick={() => setShowDeletePopup(false)}>Cancel</button>
            </div>
          </div>
        )}

        {showCouponPopup && (
          <div className="popup-overlay">
            <div className="popup-box">
              <button className="close-icon" onClick={() => setShowCouponPopup(false)}>
                <AiOutlineClose />
              </button>
              <form onSubmit={handleCreateCoupon}>
                <h2>Create New Coupon</h2>
                <input type="text" name="couponCode" placeholder="Coupon Code" required />
                <input type="number" name="discountPercentage" placeholder="Discount Percentage" required />
                <input type="date" name="expiryDate" placeholder="Expiry Date" required />
                <button type="submit">Create Coupon</button>
                    {loading && <Loader />} {/* Show loader if loading */}
              </form>
            </div>
          </div>
        )}

        {showDeleteCouponPopup && (
          <div className="popup-overlay">
            <div className="popup-box">
              <p>Are you sure you want to delete this coupon?</p>
              <button onClick={() => handleDeleteCoupon(currentCoupon._id)}>Yes, Delete</button>
              <button onClick={() => setShowDeleteCouponPopup(false)}>Cancel</button>
            </div>
          </div>
        )}
          {showProductDetailsPopup && productDetails && (
          <div className="popup-overlay">
            <div className="popup-box">
              <button className="close-icon" onClick={() => setShowProductDetailsPopup(false)}>
                <AiOutlineClose />
              </button>
              <h2>Product Details</h2>
              <p><strong>Title:</strong> {productDetails.title}</p>
              <p><strong>Category:</strong> {productDetails.category}</p>
              <p><strong>Price:</strong> {productDetails.price}</p>
              <p><strong>Description:</strong> {productDetails.description}</p>
              <img src={productDetails.images[0]} alt={productDetails.title} />
            </div>
          </div>
        )}
      </div>
    </div>
  ): (
    <Navigate to="/login" />
  );
};

export default CreateOffer;
